@import '@scss/_variables';

.product-grid {
  .row {
    > div {
      margin-bottom: 0.75rem;

      @include media-breakpoint-up(sm) {
        margin-bottom: 1.25rem;
      }

      @media screen and (min-width: 1360px) {
        flex: 0 0 270px;
        width: 270px;
        max-width: 270px !important;
      }
    }
  }
}

.product-grid-item,
.link-add-product .nav-link {
  position: relative;
  background-color: $gray-alt;
  border-radius: $border-radius-lg;
  font-size: 0.875rem;
  line-height: 1.35714;
  font-weight: 400;
  letter-spacing: 0.02143em;

  &:hover,
  &:focus {
    background-color: $gray-light;
  }
}

.product-grid-item,
.product-grid-link,
.link-add-product {
  display: flex;
  width: 100%;
  height: 100%;
}

.product-grid-link {
  flex-direction: column;
  font-weight: inherit;
}

.product-grid-pic {
  $max-img-height: 5.75rem;

  height: 100%;
  display: flex;
  padding: 0.25rem 0.5rem;
  background-color: $white;
  line-height: $max-img-height;
  text-align: center;

  &:first-child {
    border-top-left-radius: $border-radius-lg;
    border-top-right-radius: $border-radius-lg;
  }

  &:last-child {
    border-bottom-left-radius: $border-radius-lg;
    border-bottom-right-radius: $border-radius-lg;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: $max-img-height;
    width: auto;
    height: auto;
    vertical-align: bottom;
    margin: 0 auto;
  }

  .no-image {
    display: block;
    width: 100%;
    height: 9rem;
    background-image: $no-pic;
    background-repeat: no-repeat;
    background-size: auto 80%;
    background-position: 64% 50%;
  }

  @include media-breakpoint-up(sm) {
    $max-img-height: 9rem;

    padding: 1.5625rem 1.5625rem;
    line-height: $max-img-height;

    img {
      max-height: $max-img-height;
      height: auto;
    }
  }
}

.product-grid-body {
  padding: 0.625rem;

  @include media-breakpoint-up(sm) {
    padding: 0.9375rem 1.25rem;
  }
}

.product-grid-labels {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 0.625rem;
  top: 0.625rem;
  right: 0.625rem;
}

.product-grid-label {
  max-width: fit-content;
  font-size: 0.75rem;
  line-height: 1.6667;
  background-color: $blue;
  padding: 0 0.6667em;
  border-radius: $border-radius;
  white-space: nowrap;
  font-weight: 600;

  &.label-success {
    background-color: $green;
  }

  &.label-warn {
    background-color: $warning;
  }

  &.label-danger {
    background-color: $danger;
  }

  &.label-status {
    background-color: $purple-prime;
  }
}

.link-add-product {
  $icon-size: 3.5rem;
  $icon-plus-size: 1.25rem;

  .file-list & {
    color: $white;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    cursor: pointer;
  }

  .icon {
    position: relative;
    display: block;
    width: $icon-size;
    height: $icon-size;
    border: 0.125rem solid $gray-light;
    border-radius: 50%;
    margin-bottom: 1.875rem;
    transition: border-color $transition-speed-base $transition-function-base;

    &::before,
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: $icon-plus-size;
      height: 0.125rem;
      overflow: hidden;
      background-color: $gray-light;
      margin-left: -$icon-plus-size / 2;
      transition: background-color $transition-speed-base $transition-function-base;
    }

    &::after {
      transform: rotate(-90deg);
    }

    .file-list & {
      margin: 0 auto 0.5625rem;

      @media screen and (min-width: 768px) and (max-width: 1200px) {
        width: 2.875rem;
        height: 2.875rem;
      }
    }

    .item-link:first-child & {
      width: 6.375rem;
      height: 6.375rem;
      background: $drop-icon;
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border-radius: 0;
      border: 0;
      opacity: 0.2;
      transition: opacity $transition-speed-base $transition-function-base;
      margin-bottom: 1.8125rem;

      &::before,
      &::after {
        display: none;
      }
    }
  }

  .nav-link {
    height: 100%;
    width: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: $white;
    transition: background-color $transition-speed-base $transition-function-base;
    padding: 1.5rem 0.25rem;

    .file-list & {
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      border-radius: 0.125rem;
      background-color: $add-btn-bg-color;
    }

    .item-link:first-child & {
      background-color: transparent !important;
      padding-left: 1.75rem;

      strong {
        padding-right: 1.25rem;
      }
    }

    &:hover,
    &:focus,
    .file-list &:hover,
    .file-list &:focus {
      background-color: $gray-light;

      .icon {
        border-color: $white;

        .file-list .file-list-item:first-child & {
          opacity: 0.8;
        }

        &::before,
        &::after {
          background-color: $white;
        }
      }
    }
  }
}
